<template>
  <PageCover :active-path="'rezervari'"
               image="/img/cover-rezervari.webp"
               page-title="REZERVĀRI">
      <template v-slot:page-subtitle>
        <div class="alert alert-secondary mx-auto" role="alert">
          Rezervările sunt valabile numai pentru <strong>Laser Tag</strong>.
          <hr class="p-0 m-0">
          <p class="mb-0 p-0">
            Pentru petreceri private, contactaţi-ne la
            <strong>
              0770811611
            </strong>
          </p>
        </div>
      </template>
    </PageCover>
  <div v-if="loading" class="mx-auto h-100 my-auto mt-5">
    <Loading></Loading>
  </div>
  <div v-show="!loading" class="mx-auto h-100 my-auto shadow-lg mb-5 mt-5" id="calendar">
    <div class="warning-info border-start border-danger ps-4 py-2 mb-2">
      <div class="lead">
        🦠
        <span class="ps-2">
          Înainte de a efectua o rezervare, te rugăm consultă
          <router-link :to="{ name: 'covid'}"
                       target="_blank">
            <strong>
              regulamentul COVID-19
            </strong>
          </router-link>
        </span>
      </div>
    </div>
    <div class="warning-info border-start border-danger ps-4 py-2 mb-2">
      <div class="lead">
        ⚠️
        <span class="ps-2">
          Destinat persoanelor cu vârsta de peste 7 ani.
        </span>
      </div>
    </div>
    <vue-cal class=""
             active-view="week"
             :time-from="14 * 60"
             :time-to="21 * 60"
             locale="ro"
             :events="existingEvents"
             :snap-to-time="30"
             :on-event-create="onEventCreate"
             @event-drag-create="onEventCreateEnd"
             :editable-events="{
               title: false,
               drag: false,
               resize: false,
               delete: false,
               create: true
             }"
             overlaps-per-time-step
             :drag-to-create-threshold="0"
             :disable-views="['years', 'year', 'month']">
      <template v-slot:no-event>
        <div></div>
      </template>
    </vue-cal>
    <RezervariModal
      :show-modal-prop="showModal"
      :duration-extended="durationExtended"
      :duration="duration"
      :duration-minutes="durationMinutes"
      :start-date="startDate"
      :end-date="endDate"
      @modalToggled="showModal = $event"
    ></RezervariModal>
  </div>
  <Footer></Footer>
</template>

<script>
import 'vue-cal/dist/i18n/ro';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import VueCal from 'vue-cal';
import PageCover from '../components/PageCover.vue';
import RezervariModal from '../components/RezervariModal.vue';
import Loading from '../components/Loading.vue';
import Footer from '../components/Footer.vue';

const moment = extendMoment(Moment);

export default {
  name: 'Rezervari',
  components: {
    PageCover,
    VueCal,
    RezervariModal,
    Loading,
    Footer,
  },
  created() {
    fetch(`${window.apiPath}getEvents.php`)
      .then((response) => response.json())
      .then((data) => {
        this.loading = false;
        this.processExistingEvents(data);
      });
  },
  data() {
    return {
      loading: true,
      showModal: false,
      existingEvents: [],
      rangeStart: '',
      rangeEnd: '',
      calendar: null,
      time: '',
      name: '',
      phone: '',
      email: '',
      pers: '4',
      gdpr: false,
      message: '',
      error: '',
      duration: '',
      durationExtended: '',
      durationMinutes: 0,
      price: 19,
      startDate: null,
      endDate: null,
      calendarOptions: {
        themeSystem: 'bootstrap',
        calendarWeekends: true,
        eventRender: this.eventRender,
        allDaySlot: false,
        plugins: [
        ],
        selectable: true,
        googleCalendarApiKey: 'AIzaSyC3wDOAHn7uoY8374f2V8Ve7ITpMJT3svw',
        initialView: 'timeGridWeek',
        defaultView: 'timeGridWeek',
        slotDuration: '00:30:00',
        slotLabelInterval: '00:30',
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
        },
        nowIndicator: true,
        selectOverlap: false,
        selectLongPressDelay: 0,
        unselectAuto: false,
        events: {
          googleCalendarId: 'stepinthebunker@gmail.com',
        },
        validRange: {
          start: '2021-05-17 00:00',
          end: moment().startOf('month').add(999, 'month'),
        },
        selectConstraint: 'businessHours',
        selectAllow: this.selectAllow,
        slotMinTime: '14:00:00',
        slotMaxTime: '21:00:00',
        businessHours: [{
          startTime: '14:00',
          endTime: '21:00',
          daysOfWeek: [0, 1, 2, 3, 4],
        },
        {
          startTime: '14:00',
          endTime: '21:00',
          daysOfWeek: [5, 6],
        }],
        locale: 'ro',
        header: {
          left: 'prev,next today',
          center: 'title',
        },
        dayRender: this.dayRender,
        select: this.select,
      },
    };
  },
  methods: {
    eventRender(info) {
      // eslint-disable-next-line no-param-reassign
      info.el.querySelector('.fc-title').innerHTML = 'INDISPONIBIL';
      // eslint-disable-next-line no-param-reassign
      info.el.href = '#';
    },
    dayRender(date) {
      const today = new Date();
      const end = new Date();
      end.setDate(today.getDate() - 1);
      if (date.date < end) {
        // eslint-disable-next-line no-param-reassign
        date.el.style.backgroundColor = '#d7d7d7';
        // eslint-disable-next-line no-param-reassign
        date.el.style.opacity = '0.3';
      }
    },
    selectAllow(select) {
      return moment().diff(select.start) <= 0;
    },
    select(date) {
      const startDate = date.start;
      const endDate = date.end;

      let difference = moment.duration(moment(endDate).diff(startDate));
      this.durationMinutes = difference.asMinutes();

      if (difference.asHours() < 1) difference = `${difference.asMinutes()} minute`;
      else if (difference.asHours() === 1) difference = `${difference.asHours()} ora`;
      else if (difference.asHours() > 1) {
        difference = moment.utc(difference.as('milliseconds'))
          .format('h [ore] mm [minute]');
      }

      this.startDate = startDate;
      this.endDate = endDate;
      this.duration = difference;
      this.durationExtended = `Pe data de <b class='text-blue'>${moment(startDate).format('YYYY-MM-DD')}</b>`;
      this.durationExtended += ` între orele <b class='text-blue'>${moment(startDate).format('HH:mm')
      }</b> și <b class='text-blue'>${moment(endDate).format('HH:mm')}</b>`;

      // this.$refs.rezervariModal.toggleModal();
    },
    onEventCreate(evt, deleteEventFunction) {
      if (evt.startTimeMinutes === evt.endTimeMinutes) {
        if (this.deleteEventFunction) {
          this.deleteEventFunction();
        }
        return null;
      }

      this.deleteEventFunction = deleteEventFunction;
      return evt;
    },
    onEventCreateEnd(evt) {
      if (evt.startTimeMinutes === evt.endTimeMinutes) {
        if (this.deleteEventFunction) {
          this.deleteEventFunction();
        }
        return null;
      }

      const range = moment.range(moment(evt.start), moment(evt.end));
      this.existingEvents.forEach((item) => {
        const existingEventRange = moment.range(moment(item.start), moment(item.end));
        if (range.intersect(existingEventRange)) {
          if (existingEventRange.start.isAfter(range.start)) {
            // console.log('Overlaps down');
            // eslint-disable-next-line no-param-reassign
            evt.end = existingEventRange.start.toDate();
          } else if (existingEventRange.end.isAfter(existingEventRange.start)) {
            // console.log('Overlaps up');
            // eslint-disable-next-line no-param-reassign
            evt.start = existingEventRange.end.toDate();
          }
        }
      });
      if (this.deleteEventFunction) {
        this.deleteEventFunction();
      }
      this.showModal = true;
      this.select(evt);
      return null;
    },
    formatDate(date) {
      return `${new Date(date).format()} ${new Date(date).formatTime()}`;
    },
    processExistingEvents(data) {
      const existingEvents = [];
      data.forEach((evt) => {
        existingEvents.push({
          start: this.formatDate(evt.start.dateTime),
          end: this.formatDate(evt.end.dateTime),
          title: 'INDISPONIBIL',
          class: 'unavailable',
          background: true,
        });
      });
      this.existingEvents = existingEvents;
    },
  },
};
</script>

<style lang="scss">
  @import '~vue-cal/dist/vuecal.css';

  .vuecal__menu, .vuecal__cell-events-count {
    background-color: rgba(28, 189, 239, 1);
    color: black;
  }
  .vuecal__title-bar {
    color: white;
    background-color: rgba(30, 203, 255, 0.5);
  }
  .vuecal__cell--today {
    background-color: rgba(28, 189, 239, 0.1);
  }
  .vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
    background-color: rgba(28, 189, 239, 0.1);
  }
  .vuecal__cell--selected:before {
    border-color: rgb(13, 81, 100);
  }
  /* Cells and buttons get highlighted when an event is dragged over it. */
  .vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
  .vuecal__cell-split--highlighted {
    background-color: rgba(195, 255, 225, 0.5);
  }
  .vuecal__arrow {
    color: white;
  }
  .vuecal__arrow.vuecal__arrow--highlighted,
  .vuecal__view-btn.vuecal__view-btn--highlighted {
    background-color: rgba(136, 236, 191, 0.25);
  }
  .vuecal__event {
    background-color: white;
    box-sizing: border-box;
    padding: 5px;

  &.unavailable {
    color: white;
    background: repeating-linear-gradient(45deg, transparent, transparent 10px,
       rgba(242, 242, 242, 0.15) 10px, rgba(242, 242, 242, 0.15) 20px);
    .vuecal__event-time {
      font-size: 14px;
      color: #efdd10;
      font-weight: bold;
    }
   }
  .vuecal__event-time {
    font-size: 14px;
  }
}

  .warning-info {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: rgba(33,40,46,.8);
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 25px;
    box-shadow: 0 1px 4px 2px rgba(0,0,0,.3),0 0 2px 1px rgba(0,0,0,.04);
  }

  .alert-secondary {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8;

    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
</style>
<style scoped lang="scss">
  #calendar {
    max-width: 1200px;
  }
</style>
